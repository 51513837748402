import React from 'react';
import Content from '../Content';
import PropTypes from 'prop-types';

const LetnjaPageTemplate = ({ title, content, contentComponent, logo }) => {
  const PageContent = contentComponent || Content;
  return (
    <div>
      <section className='hero is-primary is-bold is-medium'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-one-fifth is-1'>
                <img alt='' src={logo} />
              </div>
              <div className='column is-10'>
                <div className='section'>
                  <h1 className='title'>{title}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section section section--gradient>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='section'>
                <PageContent
                  className='content has-text-justified'
                  content={content}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

LetnjaPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  logo: PropTypes.string,
};
export default LetnjaPageTemplate;
