import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import LetnjaPageTemplate from '../components/LetnjaPageTemplate';
import { HTMLContent } from '../components/Content';

const LetnjaPage = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.meta_title}</title>
        <meta name='description' content={post.frontmatter.meta_description} />
      </Helmet>
      <LetnjaPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        // meta_title={post.frontmatter.meta_title}
        // meta_description={post.frontmatter.meta_description}
        content={post.html}
        logo={post.frontmatter.logo}
      />
    </Layout>
  );
};
LetnjaPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};
export default LetnjaPage;

export const letnjaPageQuery = graphql`
  query LetnjaPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        logo
        meta_title
        meta_description
      }
    }
  }
`;
